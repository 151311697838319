.pulse-icon-discretely {
    animation: pulseDiscrete 2s infinite alternate;
}

@keyframes pulseDiscrete {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}

.pulse-icon-wildly {
    animation: pulseWild 2s infinite alternate;
}

@keyframes pulseWild {
    from {
        transform: scale(0.85);
    }
    to {
        transform: scale(1.15);
    }
}

/* .coin-icon {
    position: relative;
  }
  
.coin-icon::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: 120%;
    border-radius: 50%;
    background: radial-gradient(circle at 50% 50%, transparent 0%, rgba(255, 255, 255, 0.3) 50%, transparent 100%);
    opacity: 0;
    animation: light-pulse 1s ease-in-out infinite;
}
  
@keyframes light-pulse {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 0;
    }
} */

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 :root {
    --fontColor: #ffffff;
  }

  /* body {
    color: var(--fontColor);
    background-color: var(--fontColor);
  } */
  
 .dot-flashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    /* background-color: #9880ff; */
    /* background-color: #ffffff; */
    /* color: #9880ff; */
    /* color: #ffffff; */
    color: var(--fontColor);
    background-color: var(--fontColor);
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -15px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    /* background-color: #9880ff; */
    /* background-color: #ffffff; */
    /* color: #9880ff; */
    /* color: #ffffff; */
    color: var(--fontColor);
    background-color: var(--fontColor);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 15px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    /* background-color: #9880ff;
    color: #9880ff; */
    /* background-color: #ffffff;
    color: #ffffff; */
    color: var(--fontColor);
    background-color: var(--fontColor);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  @keyframes dot-flashing {
    0% {
      /* background-color: #9880ff; */
      /* background-color: #ffffff; */
      background-color: var(--fontColor);
    }
    50%, 100% {
      /* background-color: rgba(152, 128, 255, 0.2); */
      background-color: rgba(0, 0, 0, 0.2);
    }
  }